import Axios from 'axios';
import config from '../config/config';

const AmazonS3Api = Axios.create({
  baseURL: `${config.AMAZON_S3_BASE_URL}`
});

export const uploadAmazonS3 = async file => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const auth = JSON.parse(window.localStorage.getItem("auth"));
    const authorization =
      auth && auth.access_token ? `Bearer ${auth.access_token}` : "";

    const response = await AmazonS3Api.post("", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded,multipart/form-data",
        Authorization: authorization
      }
    });

    if (response.data && response.data.id) {
      return response.data;
    }

    return { id: null };
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const uploadAmazonS3Arquivo = async file => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const auth = JSON.parse(window.localStorage.getItem("auth"));
    const authorization =
      auth && auth.access_token ? `Bearer ${auth.access_token}` : "";

    const response = await AmazonS3Api.post("", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded,multipart/form-data",
        Authorization: authorization
      }
    });

    if (response.data && response.data.id) {
      return response.data;
    }

    return {id: null};
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};