import {ChevronRight} from '@mui/icons-material';
import {CircularProgress, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {QUILOMETRAGEM_ROUTE} from '../../constants/routes';
import Number from '../../utils/number';

const QuilometrosCampanha = ({campanha, kmRodado, kmRodadoDia, loading}) => {
  const navigate = useNavigate();

  if (!campanha || loading) {
    return (
      <Grid container sx={styles.container} justifyContent={'center'} alignItems={'center'}>
        <CircularProgress sx={styles.loading} />
      </Grid>
    );
  }

  return (
    <Grid container sx={styles.container} alignContent={'space-between'} component={Paper}>
      <Grid item xs={12}>
        <Typography sx={styles.titulo}>Quilômetros</Typography>
      </Grid>
      <Grid container item xs={12} alignItems="baseline" alignSelf="center">
        <Typography fontSize={12} mr={'auto'}>
          Hoje:
        </Typography>
        <Typography sx={[styles.valor, {fontSize: '20px', marginLeft: '4px'}]} title={Number.format(kmRodadoDia)}>
          {Number.format(Number.safeParseInt(kmRodadoDia))}
          <Typography component="span" sx={[styles.valor, {fontSize: '14px', marginLeft: '4px'}]}>
            {' '}
            Km
          </Typography>
        </Typography>
      </Grid>
      <Grid container item xs={12} alignItems="baseline" alignSelf="center">
        <Typography fontSize={12} mr={'auto'}>
          Total:
        </Typography>
        <Typography sx={styles.valor} title={Number.format(kmRodado)}>
          {Number.format(Number.safeParseInt(kmRodado))}
          <Typography component="span" sx={[styles.valor, {fontSize: '16px', marginLeft: '4px'}]}>
            {' '}
            Km
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} alignSelf="flex-end" onClick={() => navigate(QUILOMETRAGEM_ROUTE)}>
        <Typography sx={styles.detalhes}>
          Ver detalhes <ChevronRight />
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    borderRadius: '6px',
    backgroundColor: (theme) => theme.palette.paterns.white,
    padding: '8px 16px',
    height: '100%',
    cursor: 'default',
    boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
  },
  loading: {
    width: '20px !important',
    height: '20px !important',
  },
  titulo: {
    fontFamily: 'AktivGrotesk-Bold',
    marginBottom: '6px',
    overflow: 'hidden',
    height: '20px',
    fontSize: '14px',
  },
  detalhes: {
    fontFamily: 'AktivGrotesk-Light',
    color: (theme) => theme.palette.paterns.blueGray,
    fontSize: '12px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    svg: {
      height: '12px',
      width: '12px',
      marginBottom: '-2px',
    },
    '&:hover': {
      color: (theme) => theme.palette.paterns.blueGrayHover,
    },
  },
  valor: {
    fontFamily: 'AktivGrotesk-Medium',
    color: (theme) => theme.palette.paterns.primary,
    fontSize: '24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    alignItems: 'baseline',
  },
};

export default QuilometrosCampanha;
