import {useLazyQuery} from '@apollo/client';
import {Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {KM_RODADO_PERIODO_CAMPANHA, TOTAIS_CAMPANHA_HOME} from '../../graphql/queries';
import Data from '../../utils/data';
import Number from '../../utils/number';
import DetalhesCampanha from './detalhes-campanha';
import ImpactosCampanha from './impactos-campanha';
import MotoristasCampanha from './motoristas-campanha';
import PassageirosCampanha from './passageiros-campanha';
import QuilometrosCampanha from './quilometros-campanha';

const Home = () => {
  const {campanhaSelected, cidadeSelected} = useSelector((store) => store.Toolbar);

  const [kmRodado, setKmRodado] = useState(0);
  const [kmRodadoDia, setKmRodadoDia] = useState(0);
  const [passageiros, setPassageiros] = useState(0);
  const [impactos, setImpactos] = useState(0);
  const [loading, setLoading] = useState(true);

  const [loadPeriodos, kmDiaQuery] = useLazyQuery(KM_RODADO_PERIODO_CAMPANHA, {
    variables: {
      campanha: {
        id: campanhaSelected?.id,
      },
      campanhaCidade: cidadeSelected?.id
        ? {
            id: cidadeSelected?.id,
          }
        : null,
      inicio: Data.hoje('YYYY-MM-DD'),
      fim: Data.hoje('YYYY-MM-DD'),
    },
  });

  const [loadTotais, totalQuery] = useLazyQuery(TOTAIS_CAMPANHA_HOME, {
    variables: {
      campanha: {
        id: campanhaSelected?.id,
      },
      campanhaCidade: cidadeSelected?.id
        ? {
            id: cidadeSelected?.id,
          }
        : null,
    },
  });

  useEffect(() => {
    if (!campanhaSelected) return;
    loadTotais();
    loadPeriodos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhaSelected, cidadeSelected]);

  useEffect(() => {
    if (totalQuery.loading) return;

    setKmRodado(totalQuery.data?.kmTotal);
    setImpactos(Number.safeParseInt(totalQuery.data?.impactos));
    setPassageiros(Number.safeParseInt(totalQuery.data?.corridasTotal) * 1.4);
  }, [totalQuery]);

  useEffect(() => {
    if (kmDiaQuery.loading) return;

    setKmRodadoDia(kmDiaQuery.data?.kmPeriodo);
  }, [kmDiaQuery]);

  useEffect(() => {
    setLoading(kmDiaQuery.loading || totalQuery.loading)
  }, [kmDiaQuery.loading, totalQuery.loading]);

  return (
    <Grid id="page-container" container>
      <Grid container item xs={12} spacing={1} sx={styles.detalhesContainer}>
        <Grid item xs={4.5}>
          <DetalhesCampanha campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
        </Grid>
        <Grid item xs={2.5}>
          <QuilometrosCampanha campanha={campanhaSelected} kmRodado={kmRodado} kmRodadoDia={kmRodadoDia} loading={loading} />
        </Grid>
        <Grid item xs={2.5}>
          <ImpactosCampanha campanha={campanhaSelected} impactos={impactos} loading={loading} />
        </Grid>
        <Grid item xs={2.5}>
          <PassageirosCampanha campanha={campanhaSelected} passageiros={passageiros} loading={loading} />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={styles.motoristasContainer}>
        <MotoristasCampanha campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
      </Grid>
    </Grid>
  );
};

const styles = {
  detalhesContainer: {
    marginBottom: '8px',
    height: '167px',
  },
  motoristasContainer: {
    minHeight: 'calc(100vh - 243px)',
  },
};

export default Home;
